export const allPaintingsList = [
  {
    _id: "6493626f6975b11824efb2db",
    image: "atul",
    title: "freestyle (SOLD)",
    category: "littlepeople",
    medium: "oil on canvas",
    size: "40x100cm",
    colour: "green",
    order: 4,
  },
  {
    _id: "649363726975b11824efb2dc",
    image: "autumn",
    title: "autumn",
    category: "abstract",
    medium: "acrylic on board",
    size: "30x42cm",
    colour: "pink",
    order: 8,
  },
  {
    _id: "649364e06975b11824efb2dd",
    image: "blackface",
    title: "malaika (SOLD)",
    category: "portrait",
    medium: "pencil on paper",
    size: "21x30cm",
    colour: "black and white",
    order: 1,
  },
  {
    _id: "64936a876975b11824efb2df",
    image: "blueb",
    title: "blue birch",
    category: "landscape",
    medium: "acrylic on canvas",
    size: "20x38cm",
    colour: "blue",
    order: 4,
  },
  {
    _id: "64936c076975b11824efb2e0",
    image: "bluemed",
    title: "walking meditation",
    category: "littlepeople",
    medium: "oil on canvas",
    size: "122x122cm",
    colour: "blue",
    order: 1,
  },
  {
    _id: "64936d476975b11824efb2e1",
    image: "bob",
    title: "bob (SOLD)",
    category: "portrait",
    medium: "mixed media on canvas ",
    size: "30x30cm",
    colour: "multicolour",
    order: 4,
  },
  {
    _id: "64936e486975b11824efb2e2",
    image: "bronzebuddha",
    title: "om mane padme hum (SOLD)",
    category: "asian",
    medium: "mixed media on canvas",
    size: "40x40cm",
    colour: "bronze",
    order: 1,
  },
  {
    _id: "64936f516975b11824efb2e3",
    image: "busy",
    title: "busy",
    category: "abstract",
    medium: "oil on canvas",
    size: "40x40cm",
    colour: "multicolour",
    order: 13,
  },
  {
    _id: "64937d606975b11824efb2e4",
    image: "carmel",
    title: "silver birch (SOLD)",
    category: "landscape",
    medium: "oil on canvas",
    size: "80x80cm",
    colour: "green",
    order: 3,
  },
  {
    _id: "64937e956975b11824efb2e5",
    image: "chairs",
    title: "chairs (SOLD)",
    category: "other",
    medium: "oil on canvas",
    size: "65x100cm",
    colour: "multicolour",
  },
  {
    _id: "64937fde6975b11824efb2e6",
    image: "contemporaryd",
    title: "contemporary dance (SOLD)",
    category: "dancers",
    medium: "pencil on paper",
    size: "40x50cm",
    colour: "black and white",
    order: 3,
  },
  {
    _id: "6493809c6975b11824efb2e7",
    image: "cream",
    title: "melancholy (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "40x50cm",
    colour: "cream",
    order: 23,
  },
  {
    _id: "649381776975b11824efb2e8",
    image: "cyclist1",
    title: "tour de vasant (SOLD)",
    category: "cyclists",
    medium: "oil on canvas",
    size: "50x80cm",
    colour: "green",
    order: 1,
  },
  {
    _id: "649383ab6975b11824efb2e9",
    image: "cyclist2",
    title: "coasting (SOLD)",
    category: "cyclists",
    medium: "oil on canvas",
    size: "40x50cm",
    colour: "multicolour",
    order: 2,
  },
  {
    _id: "649385176975b11824efb2ea",
    image: "cyclist3",
    title: "determination (SOLD) ",
    category: "cyclists",
    medium: "oil on canvas",
    size: "50x80cm",
    colour: "blue",
    order: 4,
  },
  {
    _id: "649385d06975b11824efb2eb",
    image: "cyclist4",
    title: "speed (SOLD)",
    category: "cyclists",
    medium: "oil on canvas",
    size: "50x80cm",
    colour: "yellow",
    order: 3,
  },
  {
    _id: "649386716975b11824efb2ec",
    image: "cyclist5",
    title: "cruising",
    category: "cyclists",
    medium: "pen/ink/watercolour on paper",
    size: "21x21cm",
    colour: "black and white",
    order: 5,
  },
  {
    _id: "649387406975b11824efb2ed",
    image: "deereyes",
    title: "deer eyes (SOLD)",
    category: "animals",
    medium: "mixed media on paper",
    size: "21x30cm",
    colour: "grey",
    order:-2,
  },
  {
    _id: "649388616975b11824efb2ee",
    image: "doe",
    title: "deer",
    category: "animals",
    medium: "pen/ink/watercolour on paper",
    size: "21x30cm",
    colour: "sepia",
    order: -2,
  },
  {
    _id: "649388f06975b11824efb2ef",
    image: "emily",
    title: "ecstasy (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "50x50cm",
    colour: "multicolour",
    order: 12,
  },
  {
    _id: "649389f56975b11824efb2f1",
    image: "face",
    title: "orange face",
    category: "portrait",
    medium: "oil on canvas",
    size: "40x40cm",
    colour: "orange",
    order: 7,
  },
  {
    _id: "64938a6f6975b11824efb2f2",
    image: "fire",
    title: "girl on fire",
    category: "nudes",
    medium: "oil on canvas",
    size: "100x150cm",
    colour: "pink",
    order: 7,
  },
  {
    _id: "64938af26975b11824efb2f3",
    image: "flam1",
    title: "passion (SOLD)",
    category: "dancers",
    medium: "oil on canvas",
    size: "100x100cm",
    colour: "grey",
    order: 1,
  },
  {
    _id: "64938b6a6975b11824efb2f4",
    image: "flam2",
    title: "grace (SOLD)",
    category: "dancers",
    medium: "oil on canvas",
    size: "100x100cm",
    colour: "grey",
    order: 2,
  },
  {
    _id: "64938be16975b11824efb2f5",
    image: "flam3",
    title: "fire (SOLD)",
    category: "dancers",
    medium: "oil on canvas",
    size: "50x100cm",
    colour: "red",
    order: 4,
  },
  {
    _id: "64938c606975b11824efb2f6",
    image: "flam4",
    title: "amor (SOLD)",
    category: "dancers",
    medium: "oil on canvas",
    size: "40x40cm",
    colour: "red",
    order: 5,
  },
  {
    _id: "64938cdb6975b11824efb2f7",
    image: "flam5",
    title: "energy (SOLD)",
    category: "dancers",
    medium: "oil on canvas",
    size: "50x70cm",
    colour: "red",
    order: 6,
  },
  {
    _id: "64938d586975b11824efb2f8",
    image: "gaysym",
    title: "gay symbol triptych (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "90x100cm",
    colour: "red",
    order: 20,
  },
  {
    _id: "64938e486975b11824efb2f9",
    image: "geisha",
    title: "geisha (SOLD)",
    category: "portrait",
    medium: "pastel on paper",
    size: "21x30cm",
    colour: "pink",
    order: 6,
  },
  {
    _id: "64938f1d6975b11824efb2fa",
    image: "george1",
    title: "rosso (SOLD)",
    category: "nudes",
    medium: "oil on canvas",
    size: "100x150cm",
    colour: "red",
    order: 6,
  },
  {
    _id: "64938fb36975b11824efb2fb",
    image: "george2",
    title: "creativity (SOLD)",
    category: "abstract",
    medium: "acrylic on canvas",
    size: "30x30cm",
    colour: "red",
    order: 18,
  },
  {
    _id: "649390466975b11824efb2fc",
    image: "gima",
    title: "happy (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "100x160cm",
    colour: "multicolour",
    order: 9,
  },
  { _id: "6499eb9770b66f241a250182" },
  {
    _id: "6499ec1e70b66f241a250183",
    image: "goldfinches",
    title: "goldfinches (SOLD)",
    category: "animals",
    medium: "pen/ink/watercolour",
    size: "21x30cm",
    order: -1,
  },
  {
    _id: "6499ee3c70b66f241a250184",
    image: "gymnast",
    title: "flexibilty (SOLD",
    category: "nudes",
    medium: "pencil on paper",
    size: "21x30cm",
    order: 8,
  },
  {
    _id: "649c7ed1e4a36af598977488",
    image: "icy1",
    title: "richmond park 1 (SOLD)",
    category: "landscape",
    medium: "acrylic on canvas",
    size: "30x30cm",
    order: 10,
  },
  {
    _id: "649c82d4e4a36af598977489",
    image: "icy2",
    title: "richmond park 2 (SOLD)",
    category: "landscape",
    medium: "acrylic on canvas",
    size: "30x30cm",
    order: 11,
  },
  {
    _id: "649c85b8e4a36af59897748a",
    image: "ireland",
    title: "still lake (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "60x60cm",
    order: 5.5,
  },
  {
    _id: "649c87dce4a36af59897748b",
    image: "isabella",
    title: "isabella",
    category: "landscape",
    medium: "oil on canvas",
    size: "76x51cm",
    order: 2,
  },
  {
    _id: "649c8d53e4a36af59897748c",
    image: "ithaca",
    title: "ithaca",
    category: "abstract",
    medium: "oil on canvas",
    size: "102x30cm",
    order: 4,
  },
  {
    _id: "649c9438e4a36af59897748d",
    image: "josie2",
    title: "joy (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "150x125cm",
    order: 10,
  },
  {
    _id: "649c94d9e4a36af59897748e",
    image: "kath1",
    title: "vortex (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "122x152cm",
    order: 19,
  },
  {
    _id: "649c963ae4a36af59897748f",
    image: "lisajac",
    title: "shiny happy people (SOLD)",
    category: "littlepeople",
    medium: "acrylic on canvas (SOLD)",
    size: "30x50cm",
    order: 3,
  },
  {
    _id: "649c97bbe4a36af598977490",
    image: "mardisgras",
    title: "mardisgras",
    category: "abstract",
    medium: "oil on canvas",
    size: "53x91cm",
    order: 11,
  },
  {
    _id: "649c9b1fe4a36af598977491",
    image: "minis",
    title: "minis (SOLD)",
    category: "other",
    medium: "oil on canvas",
    size: "100x65cm",
  },
  {
    _id: "649c9c0ee4a36af598977492",
    image: "mirabhai",
    title: "mirabai (SOLD)",
    category: "asian",
    medium: "pastel on paper",
    size: "21x30cm",
    order: 4,
  },
  {
    _id: "649c9e2ce4a36af598977493",
    image: "nene",
    title: "nene (SOLD)",
    category: "animals",
    medium: "pen/ink/watercolour on paper",
    size: "21x30cm",
    order: -2,
  },
  {
    _id: "649c9ffbe4a36af598977494",
    image: "orangeb",
    title: "orange birch",
    category: "landscape",
    medium: "acrylic on canvas",
    size: "30x55cm",
    order: 5,
  },
  {
    _id: "649ca0b3e4a36af598977495",
    image: "orangebuddha",
    title: "buddha (SOLD)",
    category: "asian",
    medium: "mixed media on canvas",
    size: "20x45cm",
    order: 2,
  },
  {
    _id: "649ca15de4a36af598977496",
    image: "orangejacky",
    title: "zen (SOLD)",
    category: "abstract",
    medium: "acrylic on canvas",
    size: "40x60cm",
    order: 17,
  },
  {
    _id: "649ca202e4a36af598977497",
    image: "oscar",
    title: "oscar (SOLD)",
    category: "animals",
    medium: "oil on canvas",
    size: "30x30cm",
    order: 2,
  },
  {
    _id: "649ca273e4a36af598977498",
    image: "paddy",
    title: "paddy field",
    category: "asian",
    medium: "oil on canvas",
    size: "150x125cm",
    order: 3,
  },
  {
    _id: "649ca33fe4a36af598977499",
    image: "pastelf",
    title: "let me be",
    category: "nudes",
    medium: "charcoal and pastel on canvas",
    size: "40x40cm",
    order: 5,
  },
  {
    _id: "649ca465e4a36af59897749a",
    image: "pinkb",
    title: "pink birch (SOLD)",
    category: "landscape",
    medium: "acrylic on canvas",
    size: "50x20cm",
    order: 6,
  },
  {
    _id: "649ca4ece4a36af59897749b",
    image: "pj",
    title: "pj and flossy (SOLD)",
    category: "animals",
    medium: "pen/ink/watercolour",
    size: "21x30cm",
    order: 2,
  },
  {
    _id: "649ca5f1e4a36af59897749c",
    image: "raf",
    title: "dawn (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "120x150cm",
    order: 5,
  },
  {
    _id: "649ca6a1e4a36af59897749d",
    image: "yin",
    title: "yin",
    category: "abstract",
    medium: "oil on canvas",
    size: "30x80cm",
    order: 2,
  },
  {
    _id: "649ca71ae4a36af59897749e",
    image: "yellow",
    title: "yellow (SOLD)",
    category: "landscape",
    medium: "oil on canvas",
    size: "100x160cm",
    order: 9,
  },
  {
    _id: "649ca7dae4a36af59897749f",
    image: "yang",
    title: "yang",
    category: "abstract",
    medium: "oil on canvas",
    size: "30x80cm",
    order: 3,
  },
  {
    _id: "649cbecde4a36af5989774a0",
    image: "rah2",
    title: "angel (SOLD)",
    category: "portrait",
    medium: "pencil on paper",
    size: "21x30cm",
    order: 2,
  },
  {
    _id: "649cbf75e4a36af5989774a1",
    image: "reeds1",
    title: "reeds 1",
    category: "landscape",
    medium: "mixed media",
    size: "50x50cm",
    order: 7,
  },
  {
    _id: "649cc033e4a36af5989774a2",
    image: "reflections",
    title: "reflections (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "41x101cm",
    order: 7,
  },
  {
    _id: "649cc183e4a36af5989774a3",
    image: "samantha1",
    title: "compassion (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "50x20cm",
    order: 5,
  },
  {
    _id: "649cc229e4a36af5989774a4",
    image: "saavan1",
    title: "kefalonia (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "41x101cm",
    order: 6,
  },
  {
    _id: "649cc2d4e4a36af5989774a5",
    image: "sandip1",
    title: "i'm here (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "51x76cm",
    order: 1,
  },
  {
    _id: "649cc394e4a36af5989774a6",
    image: "wendy2",
    title: "lose yourself (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "100x100cm",
    order: 1,
  },
  {
    _id: "649cc587e4a36af5989774a7",
    image: "wendy4",
    title: "ash (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "70x50cm",
    order: 22,
  },
  {
    _id: "649cc62ae4a36af5989774a8",
    image: "sonia1",
    title: "tides (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "100x140cm",
    order: 2,
  },
  {
    _id: "649cc728e4a36af5989774a9",
    image: "summer",
    title: "summer (SOLD)",
    category: "abstract",
    medium: "acrylic on board",
    size: "30x42cm",
    order: 7,
  },
  {
    _id: "64ad7c81d247540a6a50c838",
    image: "squatting",
    title: "squatting (SOLD)",
    category: "nudes",
    medium: "charcoal on paper",
    size: "21x30cm",
    order: 9,
  },
  {
    _id: "64ad7fdad247540a6a50c83a",
    image: "sunset1",
    title: "orange sky",
    category: "seascape",
    medium: "oil on canvas",
    size: "80x30cm",
    order: 3,
  },
  {
    _id: "64ad8184d247540a6a50c83b",
    image: "threepeaks",
    title: "three peaks (SOLD)",
    category: "seascape",
    medium: "oil on canvas",
    size: "120x150cm",
    order: 4,
  },
  {
    _id: "64ad8391d247540a6a50c83c",
    image: "turmoil",
    title: "madness (SOLD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "150x125cm",
    order: 15,
  },
  {
    _id: "64ad84b7d247540a6a50c83d",
    image: "sherine",
    title: "sherine (SOLD)",
    category: "portrait",
    medium: "pencil on paper",
    size: "21x30cm",
    order: 3,
  },
  {
    _id: "64ad859fd247540a6a50c83e",
    image: "seatedb",
    title: "pensive",
    category: "nudes",
    medium: "oil on canvas",
    size: "50x50cm",
    order: 4,
  },
  {
    _id: "64ad8650d247540a6a50c83f",
    image: "sari",
    title: "sari (SOLD)",
    category: "asian",
    medium: "acrylic on canvas",
    size: "30x30cm",
    order: 5,
  },
  {
    _id: "64ad8796d247540a6a50c840",
    image: "standingn",
    title: "feeling blue",
    category: "nudes",
    medium: "oil on canvas",
    size: "102x128cm",
    order: 1,
  },
  {
    _id: "64ad9f33d247540a6a50c841",
    image: "winter",
    title: "winter",
    category: "abstract",
    medium: "acrylic on board",
    size: "30x42cm",
    order: 6,
  },
  {
    _id: "64ad9ff3d247540a6a50c842",
    image: "reeds2",
    title: "reeds 2",
    category: "landscape",
    medium: "acrylic on canvas",
    size: "50cmx50cm",
    order: 8,
  },
  {
    _id: "64ada086d247540a6a50c843",
    image: "ronuk1",
    title: "ronuk 1 (SOLD)",
    category: "nudes",
    medium: "acrylic on canvas",
    size: "60x90cm",
    order: 3,
  },
  {
    _id: "64ada164d247540a6a50c844",
    image: "sunset",
    title: "horizon (SOD)",
    category: "abstract",
    medium: "oil on canvas",
    size: "55x100cm",
    order: 21,
  },
  {
    _id: "64ada736d247540a6a50c845",
    image: "margareta",
    title: "margareta (SOLD)",
    category: "animals",
    medium: "pen/ink/watercolour",
    size: "21x30cm",
    order: 2,
  },
  {
    _id: "64b01a9516bbd9dfe24b4a20",
    image: "al",
    category: "nudes",
    medium: "acrylic on aluminum",
    size: "56x98cm",
    title: "over my shoulder",
    order: 2,
  },
  {
    _id: "1",
    image: "penponds",
    category: "landscape",
    medium: "oil on canvas",
    size: "76x51cm",
    title: "pen ponds",
    order: 1,
  },
  {
    _id: "2",
    image: "fishermen",
    category: "littlepeople",
    medium: "oil on canvas",
    size: "30x30cm",
    title: "sri lankan fishermen (SOLD)",
    order: 2,
  },
  {
    _id: "3",
    image: "thompsonpond",
    category: "landscape",
    medium: "oil on canvas",
    size: "100x150cm",
    title: "Thompon's Pond Isabella",
    order: 0,
  },
  {
    _id: "4",
    image: "orangedeer",
    category: "animals",
    medium: "oil on canvas",
    size: "100x100cm",
    title: "orange deer",
    order: -3,
  },
  {
    _id: "5",
    image: "angelwings",
    category: "landscape",
    medium: "oil on canvas",
    size: "50x70cm  ",
    title: "Angel Wings",
    order: 0,
  },
  {
    _id: "6",
    image: "greendeer",
    category: "animals",
    medium: "oil on canvas",
    size: "100x80cm",
    title: "green deer",
    order: -3,
  },
  {
    _id: "7",
    image: "heroninflight",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "heron in flight",
    order: 0,
  },
  {
    _id: "8",
    image: "catstretch",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "cat stretch",
    order: 1,
  },
  {
    _id: "9",
    image: "twoherons",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "margot and jerry",
    order: 0,
  },
  {
    _id: "10",
    image: "twoheronsflying",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "tom and barbara",
    order: 0,
  },
  {
    _id: "11",
    image: "catbottom",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "cat",
    order: 1,
  },
  {
    _id: "12",
    image: "seatedcat",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "seated cat",
    order: 0,
  },
  {
    _id: "13",
    image: "whitepoppy",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "white poppy",
    order: 0,
  },
  {
    _id: "14",
    image: "tig",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "tig",
    order: 6,
  },  {
    _id: "15",
    image: "thistle",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "thistle",
    order: 0,
  },{
    _id: "16",
    image: "sunblast",
    category: "abstract",
    medium: "oil on canvas",
    size: "60x80cm",
    title: "sunblast",
    order: 0,
  },{
    _id: "17",
    image: "stripeyelephant",
    category: "animals",
    medium: "acrylic on canvas",
    size: "50x50cm",
    title: "stripey elephant",
    order: -4,
  },
  {
    _id: "18",
    image: "spring",
    category: "abstract",
    medium: "acrylic on board",
    size: "30x42cm",
    title: "spring",
    order: 0,
  },{
    _id: "19",
    image: "scottishthistle",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "scottish thistle",
    order: 0,
  },{
    _id: "20",
    image: "rusty",
    category: "animals",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "rusty",
    order: 6,
  },{
    _id: "21",
    image: "purplepoppy",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "purple poppy",
    order: 0,
  },{
    _id: "22",
    image: "purpleflowers",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "purple flowers",
    order: 0,
  },{
    _id: "23",
    image: "poppyseeds",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "poppyseeds",
    order: 0,
  },{
    _id: "24",
    image: "orangepoppy",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "orange poppy",
    order: 0,
  },{
    _id: "25",
    image: "mauveflowers",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "mauve flowers",
    order: 0,
  },{
    _id: "26",
    image: "largepoppyseeds",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "large poppy seeds",
    order: 0,
  },{
    _id: "27",
    image: "jungleelephant",
    category: "animals",
    medium: "acrylic on canvas",
    size: "50x50cm",
    title: "jungle elephant",
    order: -4,
  },{
    _id: "28",
    image: "grasses1",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "grasses 1",
    order: 0,
  },{
    _id: "29",
    image: "grasses2",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "grasses 2",
    order: 0,
  },{
    _id: "30",
    image: "goldenwaterfall",
    category: "abstract",
    medium: "acrylic on canvas",
    size: "60x80cm",
    title: "golden waterfall",
    order: 0,
  },{
    _id: "31",
    image: "echinacea",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "echinacea",
    order: 0,
  },{
    _id: "32",
    image: "blueiris",
    category: "other",
    medium: "pen, ink and pen/ink/watercolour on paper",
    size: "21x30cm",
    title: "blue iris",
    order: 0,
  },{
    _id: "33",
    image: "blueelephant",
    category: "animals",
    medium: "acrylic on canvas",
    size: "50x50cm",
    title: "blue elephant",
    order: -4,
  },
];
